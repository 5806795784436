import React from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

import xIcon from 'assets/icons/xIcon.svg';

const defaultShareData = {
  title: 'Check out Picker!',
  text: 'Save time, become more efficient and get more done – work smarter! Try Picker with integrated AI chat for free for 2 months.',
};

function ShareModal({ onDone, title, shareData, sharedUrl }) {
  const theme = useTheme();

  return (
    <Dialog open={true} onClose={onDone} maxWidth="sm">
      <DialogTitle color={theme.palette.link}>
        Share Collection: {title}
      </DialogTitle>
      <DialogContent>
        <Typography>
          Choose from below or copy the link:{' '}
          <IconButton
            aria-label="share"
            size="medium"
            onClick={() => {
              navigator.clipboard.writeText(sharedUrl.href);
            }}
          >
            <AddLinkOutlinedIcon />
          </IconButton>{' '}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Stack direction="row" spacing={3}>
          <div>
            <EmailShareButton
              url={sharedUrl}
              subject={shareData?.email.title || defaultShareData.title}
              body={shareData?.email.text || defaultShareData.text}
              onShareWindowClose={onDone}
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>

          <div>
            <WhatsappShareButton
              windowWidth={660}
              windowHeight={460}
              url={sharedUrl}
              title={shareData?.whatsApp.title || defaultShareData.title}
              //separator=":: "
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
          <div>
            <FacebookShareButton
              windowWidth={660}
              windowHeight={460}
              url={sharedUrl}
              // hashtag={shareData?.facebook.hashtag}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>
          <div>
            <TwitterShareButton
              windowWidth={660}
              windowHeight={460}
              title={shareData?.xTwitter.title || defaultShareData.title}
              url={sharedUrl}
            >
              <img src={xIcon} alt="x-twitter" />
            </TwitterShareButton>
          </div>
          <div>
            <LinkedinShareButton
              url={sharedUrl}
              windowWidth={660}
              windowHeight={460}
            >
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
          </div>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default ShareModal;
